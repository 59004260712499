import React from "react";
import './Tabela.css';

class TabelaWIELOBOJZawody extends React.Component {
    render = () => {
        let wyniki_rows;
        const wyniki = this.props.wyniki;

        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{Boolean(wynik.KCZ!==undefined) && wynik.KCZ.punkty}</td>
                <td>{Boolean(wynik.KCZ!==undefined) && wynik.KCZ.X}</td>
                <td>{Boolean(wynik.KCZ!==undefined) && wynik.KCZ['10']}</td>
                <td>{Boolean(wynik.KCZ!==undefined) && wynik.KCZ['9']}</td>

                <td>{Boolean(wynik.KSP!==undefined) && wynik.KSP.punkty}</td>
                <td>{Boolean(wynik.KSP!==undefined) && wynik.KSP.X}</td>
                <td>{Boolean(wynik.KSP!==undefined) && wynik.KSP['10']}</td>
                <td>{Boolean(wynik.KSP!==undefined) && wynik.KSP['9']}</td>

                <td>{Boolean(wynik.TRAP!==undefined) && wynik.TRAP.punkty}</td>
                <td>{Boolean(wynik.TRAP!==undefined) && wynik.TRAP.trafienia}</td>

                <td>{wynik.punkty}</td>
            </tr> });
        return <div>
            <table className='table table-sm wyniki-zawody-WIELOBOJ'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>

                        <th>KCZ</th>
                        <th>X</th>
                        <th>./</th>
                        <th>9</th>
                        
                        <th>KSP</th>
                        <th>X</th>
                        <th>./</th>
                        <th>9</th>

                        <th>TRAP</th>
                        <th>Rzutek</th>
                        
                        <th>Razem</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}


class TabelaWIELOBOJPuchar extends React.Component {
    render = () => {
        let wyniki_rows;
        const wyniki = this.props.wyniki;
        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{wynik.punkty}</td>
                <td>{wynik.X}</td>
                <td>{wynik['10']}</td>
                <td>{wynik['9']}</td>
                <td>{wynik.trafienia}</td>
            </tr> });
        return <div>
            <table className='table table-sm wyniki-puchar-WIELOBOJ'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>
                        <th>Razem</th>
                        <th>X</th>
                        <th>./</th>
                        <th>9</th>
                        <th>Rzutek</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}

export { TabelaWIELOBOJPuchar, TabelaWIELOBOJZawody };