import React from 'react';
import './Tabela.css';
import dataService from "./dataService";
import SvgKczksp from './Icons/Kczksp';
import SvgTrap from './Icons/Trap';
import SvgPczpsp from "./Icons/Pczpsp";
import SvgDwuboj from "./Icons/Dwuboj";
import SvgWieloboj from "./Icons/Wieloboj";

class TabelaWynikiSmall extends React.Component {

    get_header() {
        let icon;
        let nazwa;
        switch (this.props.konkurencja) {
            case "PCZ": icon = <SvgPczpsp height={50} className="m-3"/>; nazwa = "PCZ";
                break;
            case "PSP": icon = <SvgPczpsp height={50} className="m-3"/>; nazwa = "PSP";
                break;
            case "DWUBOJ": icon = <SvgDwuboj height={50} className="m-3"/>; nazwa = "DWUBÓJ";
                break;
            case "KCZ": icon = <SvgKczksp height={50} className="m-3"/>; nazwa = "KCZ";
                break;
            case "KSP": icon = <SvgKczksp height={50} className="m-3"/>; nazwa = "KSP";
                break;
            case "TRAP": icon = <SvgTrap height={50} className="m-3"/>; nazwa = "TRAP";
                break;
            case "WIELOBOJ": icon = <SvgWieloboj height={50} className="m-3"/>; nazwa = "WIELOBÓJ";
                break;
            default: break;
        }
        return <div className="row">
                <div className="col-sm-4">{icon}</div>
                <div className="col-sm-4 center_wrapper">
                    <div>
                    <h2 className="nazwa_konkurencji">{nazwa}</h2>
                    { Boolean(this.props.pk) && <h3>pk</h3>}
                    </div>
                </div>
                <div className="col-sm-4 center_wrapper">
                    <span>{this.props.wyniki.length} zawodników</span>
                </div>
            </div>;
    }

    render() {
        let wyniki_rows;
        const apiData = new dataService();
        const wyniki = this.props.wyniki.slice(0, this.props.ile_wynikow);
        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = apiData.get_zawodnik(wynik.zawodnik_id);
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{wynik.punkty}</td>
            </tr> });
        return <div>
            { this.get_header() }
            <table className='table table-sm'>
                <thead>
                    <tr>
                        <th scope={'col'}>Miejsce</th>
                        <th scope={'col'}>Zawodnik</th>
                        <th scope={'col'}>Punkty</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>
            { <button type="button" className="btn btn-outline-primary mb-3" onClick={()=>this.props.wyswietl_wyniki_pelne(this.props.konkurencja, this.props.pk===false)}>Pełne zestawienie wyników</button>}
        </div>
    }
}

export default TabelaWynikiSmall;