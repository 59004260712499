import React from "react";

const SvgDwuboj = props => (
  <svg viewBox="0 0 207.905 232.872" {...props}>
    <path d="M177.131 23.496l-.022.692 3.56-2.798-.377 2.794 5.721-4.041-.828 2.408 4.29-1.376-1.882 2.579 4.516.516-3.763 1.634L190.98 29l-4.064-1.118 2.107 5.16-6.924-4.214.15 2.924-5.235-4.686-.052 1.59 6.096 7.933.509-4.902 8.758 5.611-3.014-6.449 7.39.989-5.506-4.988 9.043-2.838-9.42-1.16 5.4-5.934-8.162 2.451 1.419-4.386-7.301 4.73.452-3.612zM198.485 52.685l5.399-5.934-8.162 2.451 1.42-4.385-7.301 4.729.451-3.611-5.494 7.395-.023.692 3.56-2.799-.376 2.795 5.72-4.042-.828 2.408 4.291-1.376-1.882 2.58 4.516.516-3.763 1.634 2.634 3.095-4.064-1.118 2.107 5.16-6.924-4.214.15 2.924-5.235-4.686-.052 1.59 6.096 7.933.508-4.901 8.759 5.61-3.014-6.449 7.389.989-5.505-4.988 9.043-2.837zM137.206 65.261c1.582.138 2.813 1.081 3.632 2.682l1.12-.265c.023.145.045.292.045.444a2.803 2.803 0 0 1-.5 1.603c.118.442.219.909.294 1.408a3.976 3.976 0 0 0 1.385-3.014c0-.242-.031-.477-.073-.708 4.55-1.044 8.791-1.9 8.791-1.9l2.938-6.017 22.633-.038-.004-2.357 4.361-.008-.006-3.3-27.23.044-3.185-1.291-11.908.02-2.707 2.48-3.186-2.235-1.177 1.18 3.307 3.296.006 3.653-1.882 2.243s-2.326.26-4.684 1.379l8.03.701zm3.552-9.566h9v2h-9v-2zm0 4.333h9v2h-9v-2zM4.939 230.282a12.442 12.442 0 0 0 7.553 2.548c3.773 0 7.503-1.702 9.962-4.938l29.6-38.959a12.494 12.494 0 0 0 2.534-8.117l-.587-13.219-26.266-27.94 1.677 37.752-26.865 35.357c-4.176 5.497-3.105 13.339 2.392 17.516z" />
    <path d="M26.809 67.568c-6.805 20.173-8.268 33.306-5.682 55.521.95 4.887 2.631 8.436 4.764 11.015.356.518.742 1.022 1.186 1.494l35.474 37.735 9.963 49.503c1.194 5.937 6.41 10.037 12.24 10.036.817 0 1.647-.08 2.479-.248 6.769-1.361 11.15-7.952 9.788-14.72l-10.666-53a12.507 12.507 0 0 0-3.146-6.096l-26.277-27.952c.081-1.492.067-3.204-.05-5.184-2.048-15.276 1.558-32.818 4.311-46.361l-12.198-1.063c-5.658-.493-9.859-5.498-9.366-11.156.46-5.273 4.963-9.405 10.252-9.405.299 0 .601.014.904.04l30.687 2.675 51.039-8.651c2.537-.43 2.687-5.961 1.51-10.359.908-.736 1.5-1.848 1.497-3.107 0-.242-.031-.477-.073-.708 4.55-1.044 8.791-1.9 8.791-1.9l2.938-6.017 22.633-.038-.004-2.357 4.361-.008-.006-3.3-27.23.044-3.185-1.291-11.908.02-2.707 2.48-3.186-2.235-1.177 1.18 3.307 3.296.006 3.653-1.882 2.243s-6.19.686-8.605 4.703l-62.73 10.634-7.727 1.31c-7.669.615-16.319 5.925-20.325 17.574zm107.529-29.28a2.807 2.807 0 0 1-.702 1.849 12.907 12.907 0 0 0-.84-1.937c.495-.12.995-.238 1.496-.356.024.146.045.292.046.444zm-1.247-12.427h9v2h-9v-2zm0 4.334h9v2h-9v-2z" />
    <circle cx={54.287} cy={22.5} r={22.5} />
    <path d="M135.616 83.492c4.401.384 5.791-15.556 1.39-15.94l-86.422-7.534a8 8 0 0 0-1.39 15.94l86.422 7.534z" />
  </svg>
);

export default SvgDwuboj;
