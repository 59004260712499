import React from "react";
import './Tabela.css';

class TabelaDWUBOJZawody extends React.Component {
    render = () => {
        let wyniki_rows;

        const wyniki = this.props.wyniki;

        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{Boolean(wynik.PSP) && wynik.PSP.seria1}</td>
                <td>{Boolean(wynik.PSP) && wynik.PSP.seria2}</td>
                <td>{Boolean(wynik.PSP) && wynik.PSP.punkty}</td>
                <td>{Boolean(wynik.PCZ) && wynik.PCZ.seria1}</td>
                <td>{Boolean(wynik.PCZ) && wynik.PCZ.seria2}</td>
                <td>{Boolean(wynik.PCZ) && wynik.PCZ.punkty}</td>
                <td>{wynik.punkty}</td>
            </tr> });

        return <div>
            <table className='table table-sm wyniki-zawody-DWUBOJ'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>
                        <th>PSP I</th>
                        <th>PSP II</th>
                        <th>Razem<br/>PSP</th>
                        <th>PCZ I</th>
                        <th>PCZ II</th>
                        <th>Razem<br/>PCZ</th>
                        <th>Razem</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}


class TabelaDWUBOJPuchar extends React.Component {
    render = () => {
        let wyniki_rows;
        const wyniki = this.props.wyniki;
        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{wynik.punkty}</td>
                <td>{wynik.X}</td>
                <td>{wynik['10']}</td>
                <td>{wynik['9']}</td>
            </tr> });
        return <div>
            <table className='table table-sm wyniki-puchar-DWUBOJ'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>
                        <th>Razem</th>
                        <th>X</th>
                        <th>./</th>
                        <th>9</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}

export { TabelaDWUBOJPuchar, TabelaDWUBOJZawody };