import React from "react";

const SvgKczksp = props => (
  <svg viewBox="0 0 271.022 236.991" {...props}>
    <path d="M64.503 36.215l5.901-8.492 4.024.001a22.54 22.54 0 0 0 .616-5.224c0-12.427-10.072-22.5-22.5-22.5-12.426 0-22.5 10.073-22.5 22.5 0 6.745 2.972 12.792 7.673 16.916l8.013.353 18.773-3.554zM16.969 223.622c-.555 6.807 4.514 12.773 11.318 13.327 6.805.554 12.773-4.51 13.328-11.317l7.429-91.084h7.002l7.429 91.084c.555 6.808 6.523 11.872 13.328 11.317 6.805-.555 11.873-6.52 11.318-13.327l-8.53-104.591V70.983l19.083 8.377.046.02.064.028c.089.039.179.066.268.101.161.064.322.131.487.184.174.057.35.099.526.143.09.022.179.052.269.071a7.957 7.957 0 0 0 1.661.178c.018 0 .036-.004.054-.004.324-.002.648-.029.971-.07.065-.008.13-.015.195-.025.331-.051.66-.122.985-.215l.133-.041a7.94 7.94 0 0 0 1.887-.858c.062-.039.124-.077.185-.118.262-.173.519-.358.765-.565l.054-.046.024-.02 28.256-23.934a7.974 7.974 0 0 0 2.803-5.49l-11.233-.001-.004 3.826-14.352-.003-12.077 10.23-12.281-5.392a10.26 10.26 0 0 1-8.973 4.514l-31.453-1.387-22.435 11.467v47.079l-8.53 104.59zM23.061 59.39l1.684 10.366 18.538-9.475z" />
    <path d="M80.287 41.293c5.052.223 9.096 4.073 9.746 8.92l34.74.009.003-3.826 17.529.001.004-8.504 66.452.023.002-7.851-137.156-.041-5.751 8.276-10.096 1.912 24.527 1.081zm28.653-7.067l29.213.011-.001 2-29.213-.011.001-2z" />
    <path d="M79.481 59.575a8 8 0 0 0 .704-15.984L8.353 40.424C3.95 40.238.203 43.65.008 48.064a8 8 0 0 0 7.64 8.345l71.833 3.166zM214.538 29.148l-.056 1.481 8.703-5.989-.92 5.98 13.983-8.648-2.023 5.152 10.487-2.944-4.6 5.52 11.04 1.104-9.2 3.496 6.44 6.623-9.936-2.392 5.152 11.04-16.927-9.016.368 6.256-12.798-10.026-.127 3.402 14.903 16.973 1.242-10.487 21.41 12.005-7.367-13.799 18.063 2.116-13.459-10.671 22.106-6.072-23.026-2.484 13.199-12.695-19.952 5.244 3.469-9.384-17.847 10.119 1.104-7.727z" />
  </svg>
);

export default SvgKczksp;
