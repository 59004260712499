import React from "react";

const SvgWieloboj = props => (
  <svg viewBox="0 0 66 66" {...props}>
    <path d="M55.4 23.5c-1-1.3-1.9-2.9-2.7-4.5v4.4h2.7zM61.9 29.2c.9-.6 1.1-1.8.5-2.7v-.1h-3.5v.3h-5.3v.5c2.5 2.7 5.5 4 8.3 2z" />
    <circle cx={46} cy={7} r={5.6} />
    <path d="M56.7 61.5V48.2c0-.6-.2-1.2-.6-1.7-.1-.1-5.1-6.8-4.8-6.4.3-.7.4-1.4.4-2.1v-6.2l-.5.2c-.6-.6-.8-.6-1.2-3h-.2l-.2.5c.1.3 0 .5-.1.6-.3.7-1 .6-1.1.6l-2.8-.5 1.5-3-4 1.9c.6.4 1.3.7 2.1 1 1.5.6 2.2 2.2 1.7 3.7-.6 1.5-2.2 2.2-3.7 1.7-1.1-.4-2.1-.9-3-1.5.2 3.3-.4 4.9.8 7v9.8L36.1 61c-.7 1.4-.1 3.1 1.3 3.8 1.4.7 3.1.1 3.8-1.3l5.2-10.8c.2-.4.3-.8.3-1.2v-7.7c.1.1 4.4 5.9 4.2 5.5v12.4c0 1.6 1.3 2.9 2.9 2.9 1.6-.2 2.9-1.5 2.9-3.1z" />
    <path d="M57.9 24.5H48v.4h-6.6c-.2.8-.2 1.5 0 2.1.1.5.4 1 .9 1.4l4.9-2.3c.3-.2 1.2.9 1 1.2l-1.1 2.1 1.4.3s.3 0 .1-.3c0 0-.1-.1 0-.2l.4-.8s.1-.6.3-.7c0 0 .3 0 .6.1 0 0 .4.3.6.1l.3-.2s.3 2.8.6 3.1c.1 0 1.8-.5 1.7-.5 0 0-.5-1.4-.6-2.5v-1.5c.1-.1.3-.2.2-.4h5v-.3h4.8v-.7h-4.8v-.4z" />
    <path d="M41.8 23.9h5.3v-.4h4.6c-.3-1.9 1.4-7-3.3-9.2C42.9 11.8 37 20 36.6 25.4c0 .8 0 1.5.2 2.3.7 3.4 3.6 5.5 6.8 6.7 1 .4 2.1-.1 2.5-1.1.4-1-.1-2.1-1.1-2.5-1.7-.6-3.6-1.7-4.3-3.4-1-2.7 1.3-6.3 3.2-8.5.1.7-.1 1.3-.5 1.9-.8 1.2-1.3 2.2-1.6 3.1zM24.3 43.5l1-13.2-6.1 2.4c.1.8 1.1 2.7 2.3 4.4l.5.8-3.3 2.5c-.4-.5-.5.3-2.8-3.8l-1 .4-.2 3.2v.1c-.1 0-4.3-.5-4.1-.5.3-2.9 1-3.9.3-4-.1 0-.2 0-.3.1-.4.3-.7.7-1 1l.5 6.5c0 .3.3.6.6.6h.1l-.3 18c0 1.6 1.2 2.9 2.8 2.9 1.6 0 2.8-1.3 2.9-2.8l.2-18h1.4l.3 18c0 1.6 1.3 2.8 2.9 2.8 1.6 0 2.8-1.3 2.8-2.9l-.2-17.9h.1c.3 0 .6-.3.6-.6zM28.1 29.3c.2.9 1 1.4 1.9 1.4h.4c1-.2 1.7-1.3 1.4-2.3l-.2-.5-3.5 1.4z" />
    <circle cx={17.2} cy={6.5} r={5.5} />
    <path d="M9.5 35.7c.7-.7 1.4-1.2 2.2-.6.3.2.5.5.5 1l-.5 2.9 2 .2.1-2.9 2.4-1s1.9 3.4 2.4 3.8l2-1.5s-3.2-4.8-2.3-5.7c.1 0 11.9-4.6 11.8-4.6l-.2-.4c.1 0 5.6-2.2 5.2-2l-.1-.8c-.1 0-5.5 2.1-5.2 2 0 0-.2-1.3-1.7-.8l-.4-.9-.7.3.6 1.5c-18.1 7 6.4-2.5-14.1 5.4 0 0 .1-.6-1.4-.1l-.4-.9-.6.2.5 1.4-1.3.5.2.6-1.2.5c0 .4-.1.8-.2 1.2-.3.7-.8 1.2-1.6 1.5-1.2.5-2.4.1-3.2-.8l-1.4.5 2.6 4.5c1-1.6 2.3-3.3 4-5zm5.1-1.3c.5-.2 1-.1 1.1.2.1.3-.2.7-.7.9-.5.2-1 .1-1.1-.2-.1-.3.2-.7.7-.9z" />
    <path d="M5.6 35.3c.5.4 1.3.5 1.9.3.6-.2 1.1-.8 1.2-1.4.2-1.1-.9-2-1.2-5.4-.2-2.3.1-4.4 1-6.3l.7 9.5c.1.2.2.5.3.7l.2-.1-.2-.6 1.3-.5-.5-1.4 2.4-.9.4 1h.5c.2 0 .4.1.5.1l11.8-4.6.2-2.7c.1.3.3.5.4.8l2.1-.8.4 1.1h.2c.6 0 1 .3 1.3.6l.4-.1c-1.1-3.1-3.7-7.7-4.3-8.2-6.8-5-14.2-4.3-18.4-.1-3.3 3.3-4.8 7.7-4.4 12.3-.1 1.7.7 5.9 1.8 6.7z" />
  </svg>
);

export default SvgWieloboj;
