import React from 'react';
import './App.css';
import WynikiPodsumowanie from "./WynikiPodsumowanie";
import WynikiPelne from "./WynikiPelne";
import dataService from "./dataService";

const  apiData  =  new  dataService();


export default class App extends React.Component {


    state = {sposob_liczenia:"max10", zawodnicy_lista:null, zawody_lista:null, wybrane_zawody_id:-1, wyniki:null, wyniki_all:null, pk:false,
        show_pelne_konk:null, show_pelne_lic:true};


    check_data = ( self ) => {
        if (apiData.dataReady()) {
            let wyniki = apiData.get_wyniki();
            Object.keys(wyniki['per_zawody']).map( function( zaw_k ) {
                let zaw = wyniki['per_zawody'][zaw_k];
                Object.keys(zaw).map( function( wynlic_k ){
                    let wynlic = wyniki['per_zawody'][zaw_k][wynlic_k];
                    Object.keys(wynlic).map( function (wynkon_k) {
                        let wynkon = wyniki['per_zawody'][zaw_k][wynlic_k][wynkon_k];
                        wynkon.sort( function (a,b) {
                                                        return a.miejsce-b.miejsce;
                                                    } );
                    });
                });
            })
            let zawody_lista = apiData.get_zawody().sort(function (a,b) {
                                                            return new Date(a.data) - new Date(b.data);
                                                         });

            self.setState({zawody_lista:zawody_lista, wyniki_all: wyniki})
        }
    }

    componentDidMount = () => {
        var self = this;
        apiData.add_callback( function () {self.check_data(self);} )
    }

    get_zawody = zawody_id => {
        if ( zawody_id===-1 ) return {nazwa:'Puchar Czapla Cup', zawody_id:-1, data:'2019'}
        // if ( zawody_id===-2 ) return {nazwa:'Puchar Czapla Cup Klubu Ardea', zawody_id:-2, data:'2019'}
        let result = null;
        this.state.zawody_lista.map( (zawody,idx) => {
            if ( zawody.zawody_id===zawody_id ) {
                result = zawody;
            }
            return null;
        });
        return result;
    }

    get_wyniki_zawodow = () => {
        let result = null;
        const zawody_id = this.state.wybrane_zawody_id;
        if ( zawody_id===-1) {
            switch ( this.state.sposob_liczenia ) {
                case "max10": return this.state.wyniki_all['per_zawody']['PUCHAR_max10']; break;
                case "wszystkie": return this.state.wyniki_all['per_zawody']['PUCHAR_pelne']; break;
                case "bez2": return this.state.wyniki_all['per_zawody']['PUCHAR_bez2']; break;
            }
        }
        return this.state.wyniki_all['per_zawody'][zawody_id];
    }

    set_zawody = zawody_id => {
        this.setState({wybrane_zawody_id: zawody_id} );
    }

    wyswietl_wyniki_pelne = (konkurencja, lic) => {
        this.setState({show_pelne_konk:konkurencja, show_pelne_lic: lic});
    }

    handleSposobLiczenia = event => {
        this.setState({sposob_liczenia:event.target.value})
    }

    render = () => {
        let main_view;

        if (this.state.wyniki_all) {

            const zawody = this.get_zawody( this.state.wybrane_zawody_id);
            let wyniki_wybr = this.get_wyniki_zawodow();

            if (this.state.show_pelne_konk===null) {
                main_view = <div>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-10 m-5">
                            <h1 className="tytul_strony">{zawody.nazwa}</h1>

                            { Boolean(this.state.wybrane_zawody_id===-1) && <form>

                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="max10"
                                                      checked={this.state.sposob_liczenia === 'max10'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Oficjalne Wyniki Pucharu - 10 najlepszych startów zawodnika.
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="wszystkie"
                                                      checked={this.state.sposob_liczenia === 'wszystkie'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Uwzględnione wszystkie starty.
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="bez2"
                                                      checked={this.state.sposob_liczenia === 'bez2'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Usunięte dwa najgorsze starty dla każdego zawodnika.
                                      </label>
                                    </div>

                            </form>}
                        </div>
                    </div>
                    <WynikiPodsumowanie zawody={zawody} wyniki={ wyniki_wybr } wyswietl_wyniki_pelne={this.wyswietl_wyniki_pelne}/>
                </div>
            } else {
                let wyniki = wyniki_wybr.PK;
                if ( this.state.show_pelne_lic ) {
                   wyniki = wyniki_wybr.LIC;
                }

                main_view = <div>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-10 m-5">
                            <h1 className="tytul_strony">{zawody.nazwa}</h1>
                            { Boolean(this.state.wybrane_zawody_id===-1) && <form>

                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="max10"
                                                      checked={this.state.sposob_liczenia === 'max10'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Oficjalne Wyniki Pucharu - 10 najlepszych startów zawodnika.
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="wszystkie"
                                                      checked={this.state.sposob_liczenia === 'wszystkie'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Uwzględnione wszystkie starty.
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <label>
                                        <input name="sel1" type="radio" value="bez2"
                                                      checked={this.state.sposob_liczenia === 'bez2'}
                                                      onChange={this.handleSposobLiczenia} />
                                        Usunięte dwa najgorsze starty dla każdego zawodnika.
                                      </label>
                                    </div>

                            </form>}
                        </div>
                    </div>
                    <WynikiPelne zawody={zawody} wyniki={wyniki} lic={this.state.show_pelne_lic}
                                 konkurencja={this.state.show_pelne_konk} wyswietl_wyniki_pelne={this.wyswietl_wyniki_pelne}/>
                </div>
            }
        } else {
            main_view = <h2 className="text-center mt-5 ml-5">ładuję dane ...</h2>
        }


      return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <span className="navbar-brand">Zawody Czapla Cup 2019</span>
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="#">Wyniki</a>
                    </li>
                </ul>
            </nav>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 pt-5">
                        <ul className="list-group mb-2">
                            <a href="#" className={"list-group-item list-group-item-action"+(this.state.wybrane_zawody_id===-1?" active":"")}
                               onClick={()=>this.set_zawody(-1)}>
                                <b>Puchar Czapla Cup<br/>2019</b>

                                {Boolean(this.state.next_zawody_id===-1) && <div className="spinner-grow float-right" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>}
                            </a>
                        </ul>

                        <ul className="list-group mb-2">
                            { Boolean(this.state.zawody_lista) && this.state.zawody_lista.map( (zawody, idx) => {
                                let cname = "list-group-item list-group-item-action";
                                if (this.state.wybrane_zawody_id===zawody.zawody_id) cname += " active";
                                return <a key={idx} href="#" className={cname} onClick={()=>this.set_zawody(zawody.zawody_id)}>
                                    {zawody.nazwa}<br/>{zawody.data}
                                </a>
                            })}
                        </ul>
                    </div>

                    <div className="col-lg-9">
                        {main_view}
                    </div>
                    <div className="col-lg-9"></div>
                </div>
            </div>


            <footer className="page-footer font-small pt-4 bg-dark mt-5">
                <div className="container-fluid text-center text-md-left">

                </div>
                <div className="footer-copyright text-center text-white-50 py-3">© 2019 PaFka</div>

            </footer>

        </div>
      );
  }
}


