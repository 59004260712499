import React from 'react';
import './Tabela.css';
import dataService from "./dataService";

import SvgKczksp from './Icons/Kczksp';
import SvgTrap from './Icons/Trap';
import SvgPczpsp from "./Icons/Pczpsp";
import SvgDwuboj from "./Icons/Dwuboj";
import SvgWieloboj from "./Icons/Wieloboj";

import { TabelaPCZPSPZawody, TabelaPCZPSPPuchar } from './TabelaPCZPSP';
import { TabelaKCZKSPZawody, TabelaKCZKSPPuchar } from './TabelaKCZKSP';
import { TabelaTRAPZawody, TabelaTRAPPuchar } from './TabelaTRAP';
import { TabelaDWUBOJZawody, TabelaDWUBOJPuchar } from './TabelaDWUBOJ';
import {TabelaWIELOBOJPuchar, TabelaWIELOBOJZawody} from "./TabelaWIELOBOJ";
import deburr from 'lodash/deburr';

const apiData = new dataService();

class WynikiPelne extends React.Component {
    state = { wyniki_filtrowane:this.props.wyniki, }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.wyniki!==prevState.wyniki_filtrowane) {
            return { wyniki_filtrowane: nextProps.wyniki};
        }
      else return null;
    }

    filtruj_wyniki = (event) => {

        const inputValue = deburr(event.target.value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        let result={"PCZ":[], "PSP":[], "KCZ":[], "KSP":[], "TRAP":[], "DWUBOJ":[], "WIELOBOJ":[]};

        for (let key in this.props.wyniki) {
            if (inputLength === 0) {
                result[key] = this.props.wyniki[key];
            } else {
                let result_konk = this.props.wyniki[key].filter(wynik => {
                    const zawodnik = apiData.get_zawodnik(wynik.zawodnik_id);
                    const imienazwisko = zawodnik.imie + " " + zawodnik.nazwisko;

                    let keep = false;
                    if (imienazwisko.toLocaleLowerCase().includes(inputValue)) {
                        keep = true;
                    }
                    return keep;
                });
                result[key] = result_konk;
            }
        }

        this.setState({wyniki_filtrowane:result});
    }


    render = () => {
        let table = <span>not implemented yet</span>;
        let zawodnikow = 0;
        let icon;
        let nazwa;

        const lic = this.props.lic;

        let lic_info = <span>Zawodnicy bez licencji, "poza konkurencją"</span>
        if ( lic ) lic_info = <span>Zawodnicy z licencją</span>

        switch (this.props.konkurencja) {
            case "PCZ": icon = <SvgPczpsp height={50} className="m-3"/>; nazwa = "PCZ";
                zawodnikow = this.state.wyniki_filtrowane.PCZ.length;
                if ( this.props.zawody.zawody_id<0) {
                    table = <TabelaPCZPSPPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.PCZ}/>
                } else {
                    table = <TabelaPCZPSPZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.PCZ}/>
                }
                break;
            case "PSP": icon = <SvgPczpsp height={50} className="m-3"/>; nazwa = "PSP";
                zawodnikow = this.state.wyniki_filtrowane.PSP.length;
                if ( this.props.zawody.zawody_id<0) {
                    table = <TabelaPCZPSPPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.PSP}/>
                } else {
                    table = <TabelaPCZPSPZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.PSP}/>
                }
                break;

            case "DWUBOJ": icon = <SvgDwuboj height={50} className="m-3"/>; nazwa = "DWUBOJ";
                zawodnikow = this.state.wyniki_filtrowane.DWUBOJ.length;
                if ( this.props.zawody.zawody_id<0) {
                            table = <TabelaDWUBOJPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.DWUBOJ}/>
                        } else {
                            table = <TabelaDWUBOJZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.DWUBOJ}/>
                        }
                break;

            case "KCZ": icon = <SvgKczksp height={50} className="m-3"/>; nazwa = "KCZ";
                zawodnikow = this.state.wyniki_filtrowane.KCZ.length;
                if ( this.props.zawody.zawody_id<0) {
                            table = <TabelaKCZKSPPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.KCZ}/>
                        } else {
                            table = <TabelaKCZKSPZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.KCZ}/>
                        }
                break;
            case "KSP": icon = <SvgKczksp height={50} className="m-3"/>; nazwa = "KSP";
                zawodnikow = this.state.wyniki_filtrowane.KSP.length;

                if ( this.props.zawody.zawody_id<0) {
                            table = <TabelaKCZKSPPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.KSP}/>
                        } else {
                            table = <TabelaKCZKSPZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.KSP}/>
                        }
                break;

            case "TRAP": icon = <SvgTrap height={50} className="m-3"/>; nazwa = "TRAP";
                zawodnikow = this.state.wyniki_filtrowane.TRAP.length;

                if ( this.props.zawody.zawody_id<0) {
                            table = <TabelaTRAPPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.TRAP}/>
                        } else {
                            table = <TabelaTRAPZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.TRAP}/>
                        }
                break;
            case "WIELOBOJ": icon = <SvgWieloboj height={50} className="m-3"/>; nazwa = "WIELOBOJ";
                zawodnikow = this.state.wyniki_filtrowane.WIELOBOJ.length;

                if ( this.props.zawody.zawody_id<0) {
                            table = <TabelaWIELOBOJPuchar get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.WIELOBOJ}/>
                        } else {
                            table = <TabelaWIELOBOJZawody get_zawodnik={apiData.get_zawodnik} wyniki={this.state.wyniki_filtrowane.WIELOBOJ}/>
                        }
                break;
            default:break;
        }


        return <div>

            <div className="btn-toolbar mb-5" role="toolbar">
                <div className="btn-group mr-5 btn-group-lg" role="group" >
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne(null, true)}>Powrót</button>
                </div>
                <div className="btn-group ml-5 btn-group-lg" role="group">
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("PCZ", lic)}>PCZ</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("PSP", lic)}>PSP</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("DWUBOJ", lic)}>DWUBÓJ</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("KCZ", lic)}>KCZ</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("KSP", lic)}>KSP</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("TRAP", lic)}>TRAP</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.props.wyswietl_wyniki_pelne("WIELOBOJ", lic)}>WIELOBÓJ</button>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-4">{icon}</div>
                <div className="col-sm-4 center_wrapper">
                    <h2 className="nazwa_konkurencji">{nazwa}</h2>

                </div>
                <div className="col-sm-4 center_wrapper">
                    <span>{zawodnikow} zawodników</span>
                </div>
            </div>
            <h3 className="nazwa_konkurencji">{lic_info}</h3>
            <div className="form-group has-feedback has-feedback-left">
                <input type="text" className="form-control" placeholder="Szukaj zawodnika" onChange={this.filtruj_wyniki}/>
            </div>
            {table}
        </div>
    }

}

export default WynikiPelne;