import React from 'react';
import TabelaWynikiSmall from "./TabelaWynikiSmall";

function wyniki_sort(a,b) {
    return a.miejsce-b.miejsce;
}

export default class WynikiPodsumowanie extends React.Component {

    render() {
        return <div>

            <div className="row">
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.PCZ) &&
                    <TabelaWynikiSmall konkurencja="PCZ" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.PCZ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.PSP) &&
                    <TabelaWynikiSmall konkurencja="PSP" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.PSP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.DWUBOJ) &&
                    <TabelaWynikiSmall konkurencja="DWUBOJ" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.DWUBOJ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

            </div>

            <div className="row mb-3">

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.KCZ) &&
                    <TabelaWynikiSmall konkurencja="KCZ" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.KCZ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.PSP) &&
                    <TabelaWynikiSmall konkurencja="KSP" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.KSP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.TRAP) &&
                    <TabelaWynikiSmall konkurencja="TRAP" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.TRAP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.LIC.WIELOBOJ) &&
                    <TabelaWynikiSmall konkurencja="WIELOBOJ" pk={false} ile_wynikow="3" wyniki={this.props.wyniki.LIC.WIELOBOJ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

            </div>
            <h1>Zawodnicy klasyfikowani "poza konkurencją"</h1>
            <div className="row">
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.PCZ) &&
                    <TabelaWynikiSmall konkurencja="PCZ" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.PCZ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.PSP) &&
                    <TabelaWynikiSmall konkurencja="PSP" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.PSP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.DWUBOJ) &&
                    <TabelaWynikiSmall konkurencja="DWUBOJ" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.DWUBOJ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

            </div>

            <div className="row">

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.KCZ) &&
                    <TabelaWynikiSmall konkurencja="KCZ" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.KCZ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.PSP) &&
                    <TabelaWynikiSmall konkurencja="KSP" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.KSP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>
                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.TRAP) &&
                    <TabelaWynikiSmall konkurencja="TRAP" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.TRAP.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

                <div className="col-lg border m-2">
                    { Boolean( this.props.wyniki.PK.WIELOBOJ) &&
                    <TabelaWynikiSmall konkurencja="WIELOBOJ" pk={true} ile_wynikow="3" wyniki={this.props.wyniki.PK.WIELOBOJ.sort(wyniki_sort)} wyswietl_wyniki_pelne={this.props.wyswietl_wyniki_pelne}/>}
                </div>

            </div>
        </div>
    }
}