import React from "react";

const SvgPczpsp = props => (
  <svg viewBox="0 0 211.285 238.446" {...props}>
    <circle cx={48.408} cy={22.5} r={22.5} />
    <path d="M9.006 115.962c2.135 2.462 4.207 4.855 5.777 7.444l12.484-10.14c-2.022-2.99-4.236-5.553-6.172-7.786-2.211-2.55-5.238-6.041-5.084-7.433 1.617-14.578 4.853-22.84 7.864-27.3l2.045 1.084c-1.461 2.136-3.102 5.444-4.558 10.357v20.068c.562.668 1.093 1.282 1.47 1.717 1.955 2.255 4.183 4.827 6.237 7.831l1.613-1.31s.566.693 1.414 1.814l-21.09 17.131s2.179 10.555 7.427 17.789c.22.303.445.6.676.89l-6.277 76.96c-.555 6.807 4.514 12.773 11.318 13.327 6.805.554 12.773-4.51 13.328-11.317l7.592-93.083h6.676l7.592 93.083c.555 6.808 6.523 11.872 13.328 11.317 6.805-.555 11.873-6.52 11.318-13.327l-8.53-104.591V65.681l57.424-6.351-.817 2.794 1.128 1.379 11.156-.251-.752-2.131.702-3.054 5.088-.563c2.465-.273 2.991-5.401 2.203-9.764h6.516l2.512-6.048h16.07v-7.019h-38.982l-.424 1.229-1.235-.412-.595 1.784 1.217.406-.217.629-1.003 3.134h-3.008v1.839L69.11 50.286a7.795 7.795 0 0 0-1.384-.131H30.938l-.041-.007c-1.154-.143-7.265-.573-13.907 4.686-2.73 2.163-5.153 4.974-7.271 8.408C4.905 71.047 1.682 82.099.108 96.284c-.927 8.349 4.293 14.367 8.898 19.678zm149.979-74.269l-1.887 4.544h-5.846a14.395 14.395 0 0 0-.549-1.666l1.413-2.878h6.869zM198.309 34.06l7.438-8.172-11.243 3.375 1.955-6.04-10.056 6.514.622-4.975-7.568 10.187-.032.953 4.904-3.855-.518 3.849 7.879-5.567-1.14 3.317 5.909-1.895-2.592 3.553 6.221.711-5.184 2.25 3.629 4.264-5.599-1.539 2.903 7.106-9.538-5.804.207 4.028-7.211-6.455-.072 2.191 8.398 10.926.7-6.751 12.064 7.728-4.151-8.883 10.178 1.362-7.584-6.87 12.457-3.909z" />
  </svg>
);

export default SvgPczpsp;
