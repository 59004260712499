import React from "react";
import './Tabela.css';

class TabelaTRAPZawody extends React.Component {
    render = () => {
        let wyniki_rows;
        const wyniki = this.props.wyniki;

        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{wynik.punkty}</td>
                <td>{wynik.trafienia}</td>
            </tr> });
        return <div>
            <table className='table table-sm wyniki-zawody-TRAP'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>
                        <th>Razem</th>
                        <th>Rzutek</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}


class TabelaTRAPPuchar extends React.Component {
    render = () => {
        let wyniki_rows;
        const wyniki = this.props.wyniki;

        wyniki_rows = wyniki.map( (wynik, idx) => {
            const zawodnik = this.props.get_zawodnik( wynik.zawodnik_id );
            return <tr key={idx}>
                <td>{wynik.miejsce}</td>
                <td className="zawodnik"><span className='ImieNazwisko'>{zawodnik.nazwisko} {zawodnik.imie}</span><br/><span
                    className='Klub'>{zawodnik.klub}</span>
                </td>
                <td>{wynik.punkty}</td>
                <td>{wynik.trafienia}</td>
            </tr> });
        return <div>
            <table className='table table-sm wyniki-puchar-TRAP'>
                <thead>
                    <tr>
                        <th>Miejsce</th>
                        <th className="zawodnik">Zawodnik</th>
                        <th>Razem</th>
                        <th>Rzutek</th>
                    </tr>
                </thead>
                <tbody>
                { wyniki_rows }
                </tbody>
            </table>

        </div>
    }

}

export { TabelaTRAPPuchar, TabelaTRAPZawody };