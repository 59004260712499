const API_URL = '/api';
// const API_URL = 'http://cc2019.strzelnicaczapla.pl';

export default class dataService {

    constructor() {
        if (!!dataService.instance) {
            return dataService.instance;
        }
        dataService.instance = this;

        //here initialization that will be run once only.
        this.zawodnicy_lista = null;
        this.zawody_lista = null;
        this.wyniki = null;
        this.callbacks=[];

        this.getZawodyLista();
        this.getZawodnicyLista();
        this.getWyniki();
    }

    add_callback(callback) {
        this.callbacks.push(callback);
    }

    run_callbacks() {
        for (var i=0; i<this.callbacks.length; i++) {
            const callback = this.callbacks[i];
            callback();
        }
    }

    dataReady = () => {
        return ( this.zawodnicy_lista!==null) && ( this.zawody_lista !== null) && ( this.wyniki !== null);
    }

    getZawodyLista = () => {
        const url = `${API_URL}/zawody_lista/`;
        var xhttp = new XMLHttpRequest();
        var self=this;
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                self.zawody_lista = JSON.parse(this.responseText);
                self.run_callbacks();
            }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
    }

    getZawodnicyLista = () => {
        var xhttp = new XMLHttpRequest();
        var self=this;
        const url = `${API_URL}/zawodnicy_lista/`;
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                self.zawodnicy_lista = JSON.parse(this.responseText);
                self.run_callbacks();
            }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
    }

    getWyniki = () => {
        const url = `${API_URL}/wyniki/`;
        var xhttp = new XMLHttpRequest();
        var self=this;
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                self.wyniki = JSON.parse(this.responseText);
                self.run_callbacks();
            }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
    }

    get_zawodnik = zawodnik_id => {
        if ( this.zawodnicy_lista===null) return {imie:'imie', nazwisko:'nazwisko', klub:'klub'};
        return this.zawodnicy_lista[zawodnik_id];
    }

    get_zawody = () => {
        if ( this.zawody===null) return null;
        return this.zawody_lista;
    }

    get_wyniki = () => {
        if ( this.wyniki===null) return null;
        return this.wyniki;
    }
}